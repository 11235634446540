import React, { useEffect, useState } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import { useIntl } from "gatsby-plugin-intl";
import SyncLoader from 'react-spinners/SyncLoader';
import parse from 'html-react-parser';

import { qrService, getCurrentLanguage } from "../utils/utils";
import { QrSEO } from "../components/QrHead";
import QrNavbar from "../components/QrNavbar";
import QrFooter from "../components/QrFooter";
import QrSection from "../components/QrSection";
import QrError from "../components/QrError";

const PrivacyPolicy = ({ pageContext }) => {
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState('');
    const [data, setData] = useState([]);
    const intl = useIntl();

    useEffect(() => {
        async function fetchData() {
            let ret;

            setLoading(true);
            try {
                ret = await qrService('/v1/app/help', {
                    language: getCurrentLanguage(pageContext.language),
                    type: 'PRIVACY_STATEMENT'
                });
                setData(ret.privacy_statement);
                setLoading(false);
                setStatus("success");
            }
            catch (err) {
                setLoading(false);
                setStatus("error");
            }
        }

        fetchData();
    }, [pageContext.language]);

    return (
        <div id="qr-wrap">
            <QrSEO title={`Qranio - ${intl.formatMessage({ id: "FOOTER.PRIVACY_POLICY" })}`} pageName="Privacy Policy" pageClass="PrivacyPolicy" />
            <QrNavbar scrolled />

            <div id="terms" className="content-navbar-fixed">
                <QrSection id="qr-terms" gutters={true}>
                    <Container>
                        {
                            !loading ?
                                status && status === "success" ?
                                    <Row>
                                        <Col xs={12}>
                                            {parse(data)}
                                        </Col>
                                    </Row>
                                    :
                                    <QrError />
                                :
                                <div className="loading-content">
                                    <SyncLoader size={24} color="#fdc436" />
                                </div>
                        }

                    </Container>
                </QrSection>
            </div>

            <QrFooter />
        </div>
    )
}

export default PrivacyPolicy
